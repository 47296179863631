import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { createChannelMember, deleteChannelMember, getChannelMemberLevelOptions, getChannelMemberList, getChannelMemberOptions, updateChannelMember } from '@/api/channel_member';
import Md5 from '@/utils/md5';
export default {
  name: "channelMembers",
  data: function data() {
    return {
      data: [],
      loading: false,
      queryParams: {
        keyword: "",
        app_id: null,
        early_settle_day: null,
        is_subsidy_service: null,
        intelligence: null,
        page: 1,
        pageSize: 20
      },
      total: 0,
      appOptions: [{
        label: "小账本",
        value: 9
      }, {
        label: "弹个券",
        value: 8
      }, {
        label: "CPS云",
        value: 12
      }],
      intelligenceOptions: [{
        label: "一般纳税人",
        value: 1
      }, {
        label: "小规模纳税人",
        value: 2
      }, {
        label: "个人",
        value: 3
      }],
      flowOptions: [{
        label: "支付宝直跳",
        value: 1
      }, {
        label: "支付宝点击",
        value: 2
      }, {
        label: "微信直跳",
        value: 3
      }, {
        label: "微信点击",
        value: 4
      }, {
        label: "其他",
        value: 0
      }],
      levelOptions: [],
      userOptions: [],
      userLoading: false,
      formLabelWidth: '120px',
      dialogNewVisible: false,
      submitting: false,
      editIndex: '',
      newForm: {
        id: 0,
        account: "",
        password: "",
        name: "",
        company: "",
        app_id: null,
        level: null,
        expire_time: '',
        parent_id: null,
        pid: "",
        early_settle_day: 0,
        is_subsidy_service: false,
        intelligence: 1
      },
      rules: {
        account: [{
          required: true,
          message: '账号 必填'
        }]
      },
      earlySettleDaySwitch: false
    };
  },
  created: function created() {
    this.requestData();
  },
  methods: {
    appChanged: function appChanged() {
      var _this = this;

      this.levelOptions = [];
      this.userOptions = [];
      this.userLoading = false;
      this.newForm.level = null;
      this.newForm.expire_time = '';
      this.newForm.parent_id = null;
      getChannelMemberLevelOptions({
        "app_id": this.newForm.app_id
      }).then(function (res) {
        _this.levelOptions = res.data;
      });
    },
    getRemoteUser: function getRemoteUser(query) {
      var _this2 = this;

      if (query !== '') {
        this.userLoading = true;
        getChannelMemberOptions({
          "keyword": query,
          "app_id": this.newForm.app_id
        }).then(function (res) {
          _this2.userLoading = false;
          _this2.userOptions = res.data;
        });
      } else {
        this.userOptions = [];
      }
    },
    requestData: function requestData() {
      var _this3 = this;

      this.loading = true;
      getChannelMemberList(_objectSpread({}, this.queryParams)).then(function (res) {
        if (res.code === 0) {
          _this3.loading = false;
          _this3.data = res.data.list;
          _this3.total = res.data.total;
        }
      });
    },
    handleDelete: function handleDelete(index, row) {
      var _this4 = this;

      this.$confirm('您确定要删除这项数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteChannelMember({
          id: row.id
        }).then(function (res) {
          if (res.code === 0) {
            _this4.$message.success('删除成功');

            _this4.data.splice(index, 1);
          }
        });
      }).catch(function () {
        _this4.$message.info('已取消删除');
      });
    },
    handleEdit: function handleEdit(row) {
      if (row) {
        this.newForm = {
          id: row.id,
          account: row.account,
          password: "",
          name: row.name,
          company: row.company,
          app_id: row.appId,
          level: null,
          expire_time: '',
          parent_id: null,
          pid: "",
          early_settle_day: row.earlySettleDay,
          is_subsidy_service: row.isSubsidyService,
          intelligence: row.intelligence,
          flow_type: row.flowType
        };
        this.earlySettleDaySwitch = row.earlySettleDay > 0;
      } else {
        this.newForm = {
          id: 0,
          account: "",
          password: "",
          name: "",
          company: "",
          app_id: null,
          level: null,
          expire_time: '',
          parent_id: null,
          pid: "",
          early_settle_day: 0,
          is_subsidy_service: false,
          intelligence: 1
        };
      }

      this.dialogNewVisible = true;
    },
    handleSave: function handleSave(formName) {
      var _this5 = this;

      this.submitting = true;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var form = _objectSpread({}, _this5.newForm);

          if (_this5.newForm.id <= 0) {
            if (!_this5.newForm.password) {
              _this5.submitting = false;

              _this5.$message.error('请设置登录密码');

              return;
            }

            if (!_this5.newForm.app_id) {
              _this5.submitting = false;

              _this5.$message.error('请选择所属应用');

              return;
            }

            if (!_this5.newForm.level) {
              _this5.submitting = false;

              _this5.$message.error('请选择用户等级');

              return;
            }

            if (_this5.newForm.level > 1 && !_this5.newForm.expire_time) {
              _this5.submitting = false;

              _this5.$message.error('请填写用户等级到期时间');

              return;
            }
          }

          if (_this5.earlySettleDaySwitch) {
            if (form.early_settle_day <= 0 || form.early_settle_day > 30) {
              _this5.submitting = false;

              _this5.$message.error('请设置自定义结算天数，范围：1-30');

              return;
            }
          } else {
            form.early_settle_day = 0;
          }

          if (form.password) {
            form.password = Md5.hex_md5('twibosschannel&%^12yu' + form.password);
          }

          if (_this5.newForm.id > 0) {
            updateChannelMember(form).then(function (res) {
              _this5.submitting = false;

              if (res.code === 0) {
                _this5.$message.success('修改成功');

                _this5.dialogNewVisible = false;

                _this5.requestData();
              }
            }).catch(function () {
              _this5.submitting = false;
            });
          } else {
            createChannelMember(form).then(function (res) {
              _this5.submitting = false;

              if (res.code === 0) {
                _this5.$message.success('新增成功');

                _this5.dialogNewVisible = false;

                _this5.requestData();
              }
            }).catch(function () {
              _this5.submitting = false;
            });
          }
        } else {
          _this5.submitting = false;
        }
      });
    }
  }
};