var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.queryParams }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "关键字搜索：" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.queryParams.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParams, "keyword", $$v)
                      },
                      expression: "queryParams.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属应用" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.queryParams.app_id,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParams, "app_id", $$v)
                        },
                        expression: "queryParams.app_id"
                      }
                    },
                    _vm._l(_vm.appOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "结算设置" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.queryParams.early_settle_day,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParams, "early_settle_day", $$v)
                        },
                        expression: "queryParams.early_settle_day"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "1",
                        attrs: { label: "默认结算", value: 1 }
                      }),
                      _c("el-option", {
                        key: "2",
                        attrs: { label: "自定义结算", value: 2 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "补贴技术服务费" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.queryParams.is_subsidy_service,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParams, "is_subsidy_service", $$v)
                        },
                        expression: "queryParams.is_subsidy_service"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "1",
                        attrs: { label: "是", value: 1 }
                      }),
                      _c("el-option", {
                        key: "2",
                        attrs: { label: "否", value: 2 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开票资质" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.queryParams.intelligence,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParams, "intelligence", $$v)
                        },
                        expression: "queryParams.intelligence"
                      }
                    },
                    _vm._l(_vm.intelligenceOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.requestData }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleEdit(null)
                        }
                      }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.data, border: "", stripe: "" }
        },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
          _c("el-table-column", { attrs: { prop: "account", label: "账号" } }),
          _c("el-table-column", { attrs: { prop: "name", label: "备注" } }),
          _c("el-table-column", { attrs: { prop: "company", label: "公司" } }),
          _c("el-table-column", {
            attrs: { label: "推广佣金" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s((scope.row.amount / 100).toFixed(2)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "appId", label: "所属应用" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.appId === 8
                      ? [_vm._v("弹个券")]
                      : scope.row.appId === 9
                      ? [_vm._v("小账本")]
                      : scope.row.appId === 12
                      ? [_vm._v("CPS云")]
                      : [_vm._v("无")]
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "user.RecommendCode", label: "邀请码" }
          }),
          _c("el-table-column", {
            attrs: { prop: "earlySettleDay", label: "结算设置" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.earlySettleDay > 0
                      ? [_vm._v("T+" + _vm._s(scope.row.earlySettleDay))]
                      : [_vm._v("默认结算")]
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "isSubsidyService", label: "补贴技术服务费" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.isSubsidyService ? [_vm._v("是")] : [_vm._v("否")]
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "intelligence", label: "开票资质" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.intelligence === 1
                      ? [_vm._v("一般纳税人")]
                      : scope.row.intelligence === 2
                      ? [_vm._v("小规模纳税人")]
                      : scope.row.intelligence === 3
                      ? [_vm._v("个人")]
                      : [_vm._v("无")]
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.$index, scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.queryParams.page,
          "page-sizes": [20, 50, 100, 200],
          "page-size": _vm.queryParams.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total
        },
        on: {
          "size-change": _vm.requestData,
          "current-change": _vm.requestData,
          "update:currentPage": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:current-page": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          }
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "渠道用户增加/修改",
            visible: _vm.dialogNewVisible,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogNewVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "newForm",
              attrs: {
                model: _vm.newForm,
                rules: _vm.rules,
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账号", prop: "account" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.newForm.id > 0 },
                    model: {
                      value: _vm.newForm.account,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "account", $$v)
                      },
                      expression: "newForm.account"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "新增必填，修改不填则保持原有不变" },
                    model: {
                      value: _vm.newForm.password,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "password", $$v)
                      },
                      expression: "newForm.password"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司", prop: "company" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newForm.company,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "company", $$v)
                      },
                      expression: "newForm.company"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "name", $$v)
                      },
                      expression: "newForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属应用", prop: "level" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { disabled: _vm.newForm.id > 0 },
                      on: { change: _vm.appChanged },
                      model: {
                        value: _vm.newForm.app_id,
                        callback: function($$v) {
                          _vm.$set(_vm.newForm, "app_id", $$v)
                        },
                        expression: "newForm.app_id"
                      }
                    },
                    _vm._l(_vm.appOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.newForm.id <= 0 && _vm.newForm.app_id > 0
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户等级", prop: "level" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            model: {
                              value: _vm.newForm.level,
                              callback: function($$v) {
                                _vm.$set(_vm.newForm, "level", $$v)
                              },
                              expression: "newForm.level"
                            }
                          },
                          _vm._l(_vm.levelOptions, function(item) {
                            return _c("el-option", {
                              key: item.Level,
                              attrs: { label: item.Name, value: item.Level }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm.newForm.level > 1
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "过期时间", prop: "expire_time" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "datetime",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                placeholder: "选择日期时间"
                              },
                              model: {
                                value: _vm.newForm.expire_time,
                                callback: function($$v) {
                                  _vm.$set(_vm.newForm, "expire_time", $$v)
                                },
                                expression: "newForm.expire_time"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      { attrs: { label: "上级", prop: "parent_id" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              clearable: "",
                              remote: "",
                              "reserve-keyword": "",
                              placeholder: "请输入昵称/手机号/邀请码/ID",
                              "remote-method": _vm.getRemoteUser,
                              loading: _vm.userLoading
                            },
                            model: {
                              value: _vm.newForm.parent_id,
                              callback: function($$v) {
                                _vm.$set(_vm.newForm, "parent_id", $$v)
                              },
                              expression: "newForm.parent_id"
                            }
                          },
                          _vm._l(_vm.userOptions, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: {
                                label: item.ID + "/" + item.Phone,
                                value: item.ID
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "PID", prop: "pid" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "不填则保持原有不变" },
                    model: {
                      value: _vm.newForm.pid,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "pid", $$v)
                      },
                      expression: "newForm.pid"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "流量类型", prop: "intelligence" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.newForm.flow_type,
                        callback: function($$v) {
                          _vm.$set(_vm.newForm, "flow_type", $$v)
                        },
                        expression: "newForm.flow_type"
                      }
                    },
                    _vm._l(_vm.flowOptions, function(item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "结算设置", prop: "early_settle_day" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: false },
                      model: {
                        value: _vm.earlySettleDaySwitch,
                        callback: function($$v) {
                          _vm.earlySettleDaySwitch = $$v
                        },
                        expression: "earlySettleDaySwitch"
                      }
                    },
                    [_vm._v("默认结算")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: true },
                      model: {
                        value: _vm.earlySettleDaySwitch,
                        callback: function($$v) {
                          _vm.earlySettleDaySwitch = $$v
                        },
                        expression: "earlySettleDaySwitch"
                      }
                    },
                    [_vm._v("自定义结算")]
                  ),
                  _vm.earlySettleDaySwitch
                    ? [
                        _vm._v(" T + "),
                        _c("el-input", {
                          staticStyle: { width: "80px" },
                          model: {
                            value: _vm.newForm.early_settle_day,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.newForm,
                                "early_settle_day",
                                _vm._n($$v)
                              )
                            },
                            expression: "newForm.early_settle_day"
                          }
                        })
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "补贴技术服务费", prop: "is_subsidy_service" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.newForm.is_subsidy_service,
                        callback: function($$v) {
                          _vm.$set(_vm.newForm, "is_subsidy_service", $$v)
                        },
                        expression: "newForm.is_subsidy_service"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("否")
                      ]),
                      _c("el-radio", { attrs: { label: true } }, [_vm._v("是")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开票资质", prop: "intelligence" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.newForm.intelligence,
                        callback: function($$v) {
                          _vm.$set(_vm.newForm, "intelligence", $$v)
                        },
                        expression: "newForm.intelligence"
                      }
                    },
                    _vm._l(_vm.intelligenceOptions, function(item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: {
                    click: function($event) {
                      return _vm.handleSave("newForm")
                    }
                  }
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogNewVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }